import * as styles from './Navigation.module.css';

import { Link, graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useRef, useState } from 'react';

import MenuIcon from '../../components/UI/MenuIcon';
import disableScroll from 'disable-scroll';
import { getUrl } from '../../util/index';
import slugify from 'slugify';

const NavItem = (props) => {
  const links = props.childPages.map((childPage, i) => {
    return (
      <li>
        <Link to={childPage.Url||`${getUrl(props.urls, childPage.ChildPageData.id)}`} key={i}>
          {childPage.Title}
        </Link>
      </li>
    );
  });

  const aboutLinks = (
    <>
      <li>
        <Link to={`/about/team`}>Team</Link>
      </li>
      <li>
        <Link to={`/about/careers`}>Careers</Link>
      </li>
      <li>
        <Link to={`/about/blog`}>Blog</Link>
      </li>
    </>
  );

  return (
    <li className={styles.NavItem}>
      <div className={styles.Link}>
        <Link to={`${props.link}`}>{props.title}</Link>
      </div>

      <ul className={styles.Dropdown}>
        {props.title === 'About V3' ? aboutLinks : null}
        {links}
      </ul>
    </li>
  );
};

const Navigation = (props) => {
  const [showNav, setShowNav] = useState(false);
  const menuRef = useRef();
  const isBrowser = typeof window !== 'undefined';
  const navData = useStaticQuery(graphql`
    query navQuery {
      strapiPageHierarchy {
        Pages {
          Page {
            Title
            PageData {
              id
              SEO {
                Slug
              }
            }
          }
          Children {
            Title
            ChildPageData {
              id
              SEO {
                Slug
              }
              Title
            }
          }
        }
      }
      allSitePage {
        nodes {
          path
          context {
            id
          }
        }
      }
    }
  `);

  useEffect(() => {
    disableScroll.off();
  }, []);

  const toggleMobileNav = (e) => {
    e.preventDefault();
    if (isBrowser && !showNav) {
      showMobileNav();
    } else {
      hideMobileNav();
    }

    document.getElementById('nav-icon4').classList.toggle('open');

    menuRef.current.classList.toggle(styles.MenuActive);
    document.body.classList.toggle('ScrollLock');
  };

  const showMobileNav = () => {
    disableScroll.on();
    setShowNav(true);
  };

  const hideMobileNav = () => {
    disableScroll.off();
    setShowNav(false);
  };

  const pages = navData.strapiPageHierarchy.Pages;
  const urlArr = navData.allSitePage.nodes;



  //the following allows custom nav items to be added to the nav bar
  //prepend or append, anything in the page data will have to be edited in Strapi
  
  const prependNavItems = [];



  const appendNavItems = [

    //add the products link to the nav bar

    { Page: {Title: 'Products', Url: '/products' }, Children: [
      {Title: 'Cisco', Url: '/products/cisco'},
      {Title: 'Dell', Url: '/products/dell'},
      {Title: 'HPE', Url: '/products/hpe'},
      /*{Title: 'NetApp', Url: '/products/netapp'}*/
    ]},

    //adds a search link to the end of the nav
    { Page: {Title: <span style={{fontSize:'25px'}} >⌕</span>, Url: '/search' } }

  ];

  const navItems = ([ ...prependNavItems , ... pages, ...appendNavItems  ] ).map((page, i) => {

    const url = page.Page.PageData ? getUrl(urlArr, page.Page.PageData.id) : undefined;
    return (
      <NavItem
        urls={urlArr}
        link={
          page.Page.Url && page.Page.Url.length>0?
            page.Page.Url
          :url === undefined
            ? `/${slugify(page.Page.Title, { lower: true })}`
            : url
        }
        childPages={page.Children||[]}
        key={i}
        title={page.Page.Title}
      />
    );
  });


  return (
    <nav>
      <ul className={styles.Navigation} ref={menuRef}>
        {navItems}
      </ul>
      {<MenuIcon click={(e) => toggleMobileNav(e)} />}
    </nav>
  );
};

export default Navigation;
